import React from 'react';
import PropTypes from 'prop-types';
import './JoinTGCommunity.scss';
import Fade from 'react-reveal/Fade';

const JoinTGCommunity = ({ title, description, buttonLink, backgroundImage }) => {
  const backImage = !backgroundImage.childImageSharp
    ? backgroundImage
    : backgroundImage.childImageSharp.fluid.src;

  const backImageStyle = {
    backgroundImage: 'url("' + backImage + '")',
    backgroundSize: 'auto 100%',
    backgroundRepeat: 'repeat-x',
    backgroundPosition: 'center'
  };
  const animationDelay = 200;

  return (
    <section className="bg-primary section-rounded mb-0 py-11 py-xl-12" style={backImageStyle}>
      <div className="bg-primary-transparent pt-4 pb-4 pb-xl-6">
        <div className="container">
          <div className="row">
            <div className="col text-light text-center">
              <Fade bottom delay={animationDelay}>
                <h2 className="mb-3">{title}</h2>
                <p className="mb-5 mb-sm-6">{description}</p>
                <a href={buttonLink.path} className="mt-4 btn-tg btn-tg-sale" target="_blank" rel="noopener noreferrer">{buttonLink.label}</a>
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

JoinTGCommunity.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  buttonLink: PropTypes.object,
  imageContainer: PropTypes.object,
};

export default JoinTGCommunity;
