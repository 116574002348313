import React from 'react';
import PropTypes from 'prop-types';
import PreviewCompatibleImage from '../../Common/PreviewCompatibleImage';
import './ThousandsOfSongs.scss';
import vinyl from '../../../img/vinil.png';
import Fade from 'react-reveal/Fade';

const distance = '50%';

//TODO: add LP's with animation
const ThousandsOfSongs = ({ title, backgroundImage, discs }) => {
  const backImage = !backgroundImage.childImageSharp
    ? backgroundImage
    : backgroundImage.childImageSharp.fluid.src;
  const backImageStyle = {
    backgroundImage: 'url("' + backImage + '")',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  };

  return (
    <section style={backImageStyle}>
      <div className="container py-7">
        <div className="row">
          <div className="col">
            <Fade right distance={distance}>
              <h2 className="text-light text-center mb-5">{title}</h2>
            </Fade>
          </div>
        </div>
        <div className="row">
          {
            discs.map((item, i) => (
              <Fade key={i} left delay={(i + 1) * 200} distance={distance}>
                <a
                  href={`${process.env.TOTALLY_LESSONS_URL}/sign-up`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="col col-12 col-sm-6 col-lg-4">
                  <section className="disc mb-5">
                    <h3 className="text-light font-weight-bold text-uppercase disc-title">
                      <div className="mx-2 mx-md-3 mx-xl-4">{item.title}</div>
                    </h3>
                    {vinyl && (<PreviewCompatibleImage
                      imageInfo={{
                        image: vinyl,
                        alt: 'vinyl',
                        className: 'vinyl'
                      }}
                    />)}
                    {item && item.imageContainer && (<PreviewCompatibleImage
                      imageInfo={{
                        image: item.imageContainer.src,
                        alt: item.imageContainer.alt,
                        className: 'disc-img'
                      }}
                    />)}
                  </section>
                </a>
              </Fade>
            ))
          }
        </div>
      </div>
    </section>
  );
};

ThousandsOfSongs.propTypes = {
  title: PropTypes.string,
  backgroundImage: PropTypes.object,
  discs: PropTypes.array,
};

export default ThousandsOfSongs;
