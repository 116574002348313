import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';

const animationDelay = 300;
const distance = '50%';


const JoinForFree = ({ title, description, buttonLink, backgroundImage }) => {
  const backImage = !backgroundImage.childImageSharp
    ? backgroundImage
    : backgroundImage.childImageSharp.fluid.src;

  const backImageStyle = {
    backgroundImage: 'url("' + backImage + '")',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  };

  return (
    <section style={backImageStyle} className="bg-primary text-center text-white mask-dark">
      <Fade bottom delay={animationDelay} distance={distance}>
        <div className="container py-12">
          <div className="row justify-content-center align-items-center">
            <div className="col col-12 col-lg-6">
              <h2 className="mb-5">{title}</h2>
              <p className="mb-6">{description}</p>
              <a href={buttonLink.path} className="btn-tg btn-tg-sale" target="_blank" rel="noopener noreferrer">{buttonLink.label}</a>
            </div>
          </div>
        </div>
      </Fade>
    </section>
  );
};

JoinForFree.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  buttonLink: PropTypes.object,
  backgroundImage: PropTypes.object,
};

export default JoinForFree;
